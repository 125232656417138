<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} categoría de proveedor`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="supplierCategory.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de categorías de proveedor"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeSupplierCategories"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue')
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      supplierCategory: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      supplierCategories: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar'
    },
    activeSupplierCategories() {
      return this.supplierCategories.filter(category => category.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.supplierCategory)
    }
  },
  async created() {
    this.supplierCategory.id_autor = this.userData.id
    this.supplierCategory.id_institucion_educativa = this.institutionId
    this.supplierCategories = await this.fetchSupplierCategoriesByEI(this.institutionId)

  },
  methods: {
    async fetchSupplierCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener categorias de proveedor por institucion educativa', error);
      }
    },
    async createSupplierCategory() {
      try {
        const response = await api.post('/personas/crear-categoria-proveedor', {
          nombre_categoria_proveedor: this.supplierCategory.dato,
          id_institucion_educativa: this.supplierCategory.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la categoria de proveedor.')
        }
      } catch (error) {
        console.error('Error al intentar crear categoria de proveedor', error)
        this.errors.push('Error al intentar crear categoria de proveedor')
      }
    },
    async updateSupplierCategory() {
      try {
        const response = await api.patch('/personas/update-categoria-proveedor', {
          id: this.supplierCategory.id,
          categoria_proveedor: this.supplierCategory.dato,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la categoria de proveedor')
        }
      } catch (error) {
        console.error('Error al intentar actualizar categoria de proveedor', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteSupplierCategory(typeId) {
      try {
        const response = await api.post('/personas/delete-categoria-proveedor', {
          id_categoria_proveedor: typeId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la categoria de proveedor')
        }
      } catch (error) {
        console.error('Error al intentar eliminar categoria de proveedor', error);
      }
    },
    async save() {
      await this.createSupplierCategory()
      this.supplierCategories = await this.fetchSupplierCategoriesByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.supplierCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.supplierCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      // console.log('updateInline item', item);
      this.supplierCategory = {...item}
      await this.updateSupplierCategory()
      this.supplierCategories = await this.fetchSupplierCategoriesByEI(this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deleteSupplierCategory(itemId)
      this.supplierCategories = await this.fetchSupplierCategoriesByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>